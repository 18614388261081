<template>
    <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-7">
        <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
            <inline-svg src="/media/icons/duotune/general/gen044.svg"/>
        </span>
        <div class="d-flex flex-stack flex-grow-1">
            <div class="fw-bold">
                <h4 class="text-gray-800 fw-bolder">{{ $t('messages.warning') }}</h4>

                <div class="fs-6 text-gray-600">{{ $t('pages.trash.warnings.trash') }}</div>
            </div>
        </div>
    </div>
    <custom-table
        :title="$t('pages.trash.title')"
        :subTitle="$t('pages.trash.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        changeUrlPage
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:information="{ row: record }">
            <div class="d-flex align-items-center">
                <div>
                    <div class="symbol symbol-60px symbol-2by3 flex-shrink-0 me-4 border">
                        <img :src="record.image ? record.image.direct_link : '/media/svg/files/blank-image.svg'" alt="">
                    </div>
                </div>
                <div>
                    <div class="fs-5 text-gray-800 fw-bolder">
                        {{ record.title }}
                    </div>
                    <div class="text-gray-400 fw-bold fs-7 my-1">
                        {{ $t('pages.trash.cols.group') }}: {{ record.group ? record.group.name : "-" }}
                    </div>
                    <div class="text-gray-400 fw-bold fs-7 my-1">
                        {{ $t('pages.trash.cols.extension') }}: {{ record.extension }}
                    </div>
                    <div class="text-gray-400 fw-bold fs-7 my-1">
                        {{ $t('pages.trash.cols.deletedAt') }}: {{ $moment(record.deleted_at).format('DD.MM.YYYY - HH:mm') }}
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:detail="{ row: record }">
            <div class="text-end">
                <div class="text-gray-800 fw-boldest fs-3">
                    {{ record.duration_text }}
                </div>

                <div class="text-gray-400 fs-7 fw-bold d-block">
                    {{ record.size_detail.text }}
                </div>

                <div class="text-gray-400 fs-7 fw-bold d-block">
                    {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
                </div>
            </div>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-popconfirm :title="$t('pages.trash.messages.sureRecover')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="recoverRecord(record)">
                    <template #reference>
                        <a class="btn btn-icon btn-success btn-sm me-1">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/abstract/abs040.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
                <el-popconfirm :title="$t('pages.trash.messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-danger btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "@/components/custom-table";
export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("pages.trash.cols.information"),
                    class: "min-w-300px w-100",
                    scopedSlots: {customRender: "information"}
                },
                {
                    name: this.$t("pages.trash.cols.detail"),
                    class: "min-w-150px text-end",
                    scopedSlots: {customRender: "detail"},
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
        }
    },
    computed: {
        table() {
            return this.$store.state.trash.table;
        },
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.contentManagement.trash"), [this.$t("menu.contentManagement.title")]);
        this.$store.dispatch('trash/get', {
            page: {
                pageSize: 50
            },
            filterData: Object.assign(this.$root.getFilterWithUrl({}), {
                sort: 'deleted_at:desc',
            })
        });
    },
    methods: {
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                default:
                    break;
            }
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("trash/get", {
                page: pagination,
                filterData: Object.assign({
                    sort: 'deleted_at:desc',
                }, filterData)
            });
        },
        refreshTable(){
            this.$store.dispatch("trash/refresh");
        },
        deleteRecord(id){
            this.$store.dispatch("trash/delete", {
                id: id
            });
        },
        recoverRecord(record) {
            this.axios.get(this.sprintf('%s/%d', [this.endpoint.get('trash_recover'), record.id])).then(response => {
                this.onResponse(response.data, () => {
                    this.refreshTable();
                });
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            });
        }
    }
}
</script>

<style scoped>

</style>